import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/avatar.png";
import Particle from "../Particle";
import Home2 from "./Home2";
import Mint from "./Mint";
import Roadmap from "./Roadmap";
import Type from "./Type";
import Type2 from "./Type2";




function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Garden Patch <span className="wave" role="img" aria-labelledby="wave">🥦</span><span className="wave" role="img" aria-labelledby="wave">🌶</span><span className="wave" role="img" aria-labelledby="wave">🍆</span><span className="wave" role="img" aria-labelledby="wave">🥒</span><span className="wave" role="img" aria-labelledby="wave">🥕</span><span className="wave" role="img" aria-labelledby="wave">🐝</span>
              </h1>

              <h1 className="heading-name">
                WE ARE
                <strong className="main-name"> THE STEAMED VEGGIES</strong>
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
              <div style={{ padding: 35, textAlign: "left" }}>
                <Type2 />
              </div>
            </Col>

            <Col md={5}  style={{ paddingBottom: 20 }}>
              
              <img src={homeLogo} alt="home pic" className="img-fluid" />
              
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
      <Roadmap/>
      <Mint />

    </section>
  );
}

export default Home;
