import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import dots from "../../Assets/Projects/dots.png";

import zeebros from "../../Assets/Projects/zeebros.png";
import gardenpatch from "../../Assets/Projects/gardenpatch.gif"

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          <strong className="purple">NFT</strong>s
        </h1>
        <p style={{ color: "white" }}>
          We are  on <strong className="purple">Polygon</strong>.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
          <ProjectCard
              imgPath={gardenpatch}
              isBlog={false}
              title="Garden Patch"
              description="Garden Patch Veggies collection commemorating the release of their very first EP. First 20% Veggies are 2 MATIC!"
              link="https://mintveggies.broccolirecords.com/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dots}
              isBlog={false}
              title="DOTs"
              description="42000 DOTs"
              link="https://dots.dblz.art"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={zeebros}
              isBlog={false}
              title="ZeeBros"
              description="A collection of 420 Zee's scattered about. Purchasing a ZeeBro gets you exclusive perks, merchandise and first looks into upcoming music and projects from DBL Z."
              link="https://zeebros.dblz.art/"
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Editor.io"
              description="Online code and markdown editor build with react.js. Online Editor which supports html, css, and js code with instant view of website. Online markdown editor for building README file which supports GFM, Custom Html tags with toolbar and instant preview.Both the editor supports auto save of work using Local Storage"
              link="https://github.com/soumyajit4419/Editor.io"
            />
          </Col> */}

          
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
