import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.png";
import ProjectCard from "../Projects/ProjectCards";

import {
  AiFillCloud,
  AiFillInstagram,
} from "react-icons/ai";

import gardenpatch from "../../Assets/Projects/gardenpatch.gif";



function Mint() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
        <Col md={8} className="myAvtar">
            
              <img src={myImg} className="img-fluid" alt="avatar" />
            
          </Col>
          <Col md={4} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              MINT <span className="purple"> VEGGIES </span>
            </h1>
            
            <ProjectCard
              imgPath={gardenpatch}
              isBlog={false}
              title="Garden Patch"
              description="Garden Patch Veggies collection commemorating the release of their very first EP. First 20% Veggies are 2 MATIC!"
              link="https://mintveggies.broccolirecords.com/"
            />
          </Col>
          
        </Row>

        <Row>
            <Col md={12} className="home-about-description">
                We are on <span className="purple">Polygon</span>
            
            </Col>
        </Row>
        
        <br></br>
      
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND US ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with us
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://soundcloud.com/broccoli-records-music"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillCloud />
                </a>
              </li>
              
              
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/broccoli.records/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Mint;
