import React from "react";
import Typewriter from "typewriter-effect";

function Type2() {
  return (
    <Typewriter
      options={{
        strings: [
          "DBL Z",
          "Fury",
          "Sidoobee",
          "Slum Chemist R.Y",
          "Supamedo",
          "M4",
          "Bankorrupt",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 60,
      }}
    />
  );
}



export default Type2;
