import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/goldavatar.png";


function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              ABOUT <span className="purple"> GARDEN PATCH </span>
            </h1>
            
            <p className="home-about-body">
              
              <br />
              <iframe title="Player" 
              width="100%" 
              height="320"
              scrolling="no" 
              frameborder="no" 
              allow="autoplay" 
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1375680154&color=%2338831d&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true">
                </iframe>
                
              </p>
          </Col>
          <Col md={4} className="myAvtar">
            <br></br>
            <br></br>
            
              <img src={myImg} className="img-fluid" alt="avatar" />
            
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-description">
          <strong>
              <b className="purple"> Steamed Veggies </b> 1st studio EP: <b className="purple"> Garden Patch </b>, released on January 21, 2022, by Broccoli Records.
Garden Patch includes contributions from Broccoli artists, <b className="purple"> DBL Z </b>, <b className="purple"> Fury </b>, <b className="purple"> Sidoobee </b>, <b className="purple"> Slum Chemist R.Y</b>, <b className="purple"> Supamedo</b>, <b className="purple"> M4</b> and <b className="purple"> Bankorrupt</b>. The EP features productions from a variety of upcoming independent producers, including RYGOR, SIXZIN, Noria, theskybeats and Call Me G. Recording sessions for Garden Patch took place in Yanbu, Saudi Arabia at Broccoli Studios.
                
              </strong>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
