import React from "react";
import { Container, Row, Col } from "react-bootstrap";


function Roadmap() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
            <Row>
                <Col md={12} className="home-about-description">
                <h1 style={{ fontSize: "2.4em" }}>
                    <span className="purple"> ROADMAP </span>
                    </h1>
                </Col>
            </Row>
            
            <Row>
                <Col md={8} className="home-about-description">
                    <h1 style={{ fontSize: "1.6em" }}>
                        <Row>Garden patch veggies were organized by Veggie performance bars on <span className="purple"> Garden Patch EP</span>.</Row>
                        <Row>20 Veggies will be held by Broccoli Records for gifts and giveaways.</Row>
                        <Row>There are 30 <span className="gold"> Golden Veggies</span> that start from Veggie#242.</Row>
                        <Row>Last 10 Veggies are the <span className="gold"> Golden Veggies</span> Collective.</Row>
   
                    </h1>
                </Col>

                <Col md={4} className="home-about-description">
                <h1 style={{ fontSize: "1.3em" }}>
                    <Row><span className="purple">Order of Veggies:</span></Row>

                    <Row>#1-#113| <span className="purple"> DBL Z</span></Row>
                    <Row>#114-#153| <span className="purple"> Sidoobee</span></Row>
                    <Row>#154-#177| <span className="purple"> Slum Chemist R.Y</span></Row>
                    <Row>#178-#193| <span className="purple"> Fury</span></Row>
                    <Row>#194-#209| <span className="purple"> M4</span></Row>
                    <Row>#210-#225| <span className="purple"> Supamedo</span></Row>
                    <Row>#226-#241| <span className="purple"> Bankorrupt</span></Row>
                    <Row>#242-#286| <span className="gold"> Rare Veggies</span></Row>
                    
                    </h1>         
                </Col>

                <Col md={4} className="home-about-description">
                            
                </Col>
            </Row>
            <Row>
                <Col md={12} className="home-about-description">
                <h1 style={{ fontSize: "2.4em" }}>
                    <span className="purple"> Timeline </span>
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col md={12} className="home-about-description">
                <h1 style={{ fontSize: "1.6em" }}>

                    <Row><span className="purple">30% sold</span>| Release of main single on major streaming platform </Row>
                    <Row><span className="purple">50% sold</span>| Release of full EP on major streaming platforms </Row>
                    <Row><span className="purple">75% sold</span>| Music video of main single and Broccoli merchandise distribution to lucky fans </Row>
                    <Row><span className="gold">95% sold</span>| 2nd music video with appearances by holders of golden veggies </Row>

                    </h1>   
          
                </Col>
            </Row>
        </Container>
    </Container>
  );
}
export default Roadmap;
